// TODO: fix this
/* tslint:disable-next-line */
import {PageContentSlot} from '@wf-mfe/layout';
import React, {Component} from 'react';
import {Localization} from "../helpers/BoundLocalization";

export class ResourceReportingPage extends Component {

    render() {
        return (
            <div data-wf-reactroot>
                <Localization
                    messageKey="hello.world"
                    fallback="Hello World"
                />
            </div>
        );
    }
}

export const ResourceReportingContainer = (options) => {

    return (
        <PageContentSlot mfeName="@wf-mfe/rm-reporting-ui">
            <div
                className={`responsive-content flex flex-col flex-1 h-full w-full overflow-auto relative z-0 pb-0 p-4`}>
                <ResourceReportingPage
                    {...options}
                />
            </div>
        </PageContentSlot>
    );
};