import { createReactLifecycles } from 'single-spa-workfront'
import { ResourceReportingContainer } from './components/ResourceReportingPage';

export { ResourceReportingPage } from './components/ResourceReportingPage';

const { bootstrap, mount, unmount } = createReactLifecycles({
  rootComponent: ResourceReportingContainer,
  suppressComponentDidCatchWarning: true,
})

export { bootstrap, mount, unmount }