import { BrowserClient, createBoundLocalization } from '@wf-mfe/localize-react'

export const localizationClient = new BrowserClient({
    namespace: ['app'] // will be changed after adding our own namespace
})

const {
    Localization,
    useLocalization,
} = createBoundLocalization(localizationClient)

export { Localization, useLocalization }
